import { Box, Typography } from '@mui/material';

const Section1 = () => {
  return (
    <Box
      sx={{
        bgcolor: 'primary.light',
        color: 'primary.contrastText',
        px: 2,
        py: 3,
      }}
    >
      <Typography
        data-aos='zoom-in'
        align='center'
        sx={{
          fontFamily: `'Montserrat', sans-serif`,
          fontWeight: 500,
          fontSize: '0.875rem',
        }}
      >
        Salah satu penyebab terjadinya{' '}
        <Typography
          component='span'
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 700,
            fontSize: '0.875rem',
            textTransform: 'uppercase',
          }}
        >
          penuaan dini
        </Typography>{' '}
        yaitu{' '}
        <Typography
          component='span'
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 700,
            fontSize: '0.875rem',
            textTransform: 'uppercase',
          }}
        >
          aktivitas tidak sehat
        </Typography>{' '}
        di lingkungan sekitarmu
      </Typography>
      <Typography
        data-aos='zoom-in'
        data-aos-delay={500}
        align='center'
        sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          fontFamily: `'Montserrat', sans-serif`,
          mt: 0.75,
        }}
      >
        Gak Percaya ?
      </Typography>
    </Box>
  );
};
export default Section1;
