import { Box, Typography } from '@mui/material';
import BPOM from '../../assets/bpom_logo.png';
import bottle from '../../assets/lunoar_discon.png';

const Section7 = () => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography
        data-aos='zoom-out'
        data-duration={500}
        align='center'
        sx={{
          fontFamily: `'Montserrat', sans-serif`,
          fontWeight: 700,
          fontSize: '1.25rem',
        }}
        color='primary.main'
      >
        Bayangkan Serum Wajah ini dengan Semua Kandungan diatas harganya cuma
      </Typography>
      <Box
        sx={{ display: 'flex', gap: 2, my: 3, px: 3, justifyContent: 'center' }}
      >
        <Box
          data-aos='zoom-out'
          data-aos-duration={750}
          component='img'
          src={BPOM}
          sx={{
            height: 0.1,
            display: 'flex',
            alignSelf: 'flex-end',
            width: 0.2,
          }}
        />
        <Box
          data-aos='zoom-out'
          data-aos-duration={750}
          component='img'
          src={bottle}
          sx={{ width: 0.6 }}
        />
      </Box>
      {/* <Typography
        data-aos='zoom-out'
        data-duration={500}
        align='center'
        sx={{
          fontWeight: 700,
          fontFamily: `'Montserrat', sans-serif`,
          fontSize: '128px',
          lineHeight: 1,
          mt: 4,
        }}
      >
        <Typography
          component='span'
          sx={{
            fontWeight: 700,
            fontFamily: `'Montserrat', sans-serif`,
            color: '#ff0000',
            verticalAlign: 'top',
            fontSize: '36px',
          }}
        >
          *
        </Typography>
        50
        <Typography
          component='span'
          sx={{
            fontWeight: 700,
            fontFamily: `'Montserrat', sans-serif`,
            verticalAlign: 'top',
            fontSize: '36px',
          }}
        >
          rb
        </Typography>
      </Typography>*/}
      <Typography
        data-aos='zoom-out'
        data-duration={500}
        align='center'
        sx={{
          fontFamily: `'Montserrat', sans-serif`,
          fontWeight: 700,
          fontSize: '1.5rem',
          mt: 4,
        }}
        color='primary.main'
      >
        Murah Banget Kan ???
      </Typography>
    </Box>
  );
};
export default Section7;
