import { Box } from '@mui/material';
import { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FloatingButton = () => {
  const [bottom, setBottom] = useState(16);

  window.onscroll = function (event) {
    const myHtml = document.querySelector('html');
    if (myHtml.offsetHeight + myHtml.scrollTop + 20 >= myHtml.scrollHeight) {
      setBottom(54);
    } else {
      setBottom(16);
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: bottom,
        display: 'flex',
        justifyContent: 'center',
        width: 1,
        zIndex: 10,
      }}
    >
      <Box
        data-aos="zoom-in-up"
        data-aos-duration={1000}
        data-aos-offset={0}
        variant="contained"
        onClick={() => {
          ReactPixel.track('Lead', {
            content_name: 'WhatsApp',
          });

          window.open(
            'https://api.whatsapp.com/send/?phone=6282288898001&text=Halo+Siss,%20+Saya+Mau+Tanya2+tentang+Serum+Lunoar+dan+Promo+Diskonnya+donkk...&app_absent=0'
          );
        }}
        sx={{
          fontSize: '1.125rem',
          borderRadius: '8px',
          padding: '8px 16px',
          fontWeight: 700,
          fontFamily: `'Poppins', sans-serif`,
          bgcolor: '#ffe179',
          // color: '#fff',
          cursor: 'pointer',
        }}
        color="primary.main"
      >
        KLIK DISINI DAPATKAN <br /> PROMO FREE ONGKIR !
      </Box>
    </Box>
  );
};
export default FloatingButton;
