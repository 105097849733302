import { Box, Container, useMediaQuery } from '@mui/material';
import Banner from '../../components/Basic/Banner';
import BuyNow from '../../components/Basic/BuyNow';
import Bottle from '../../components/Basic/Bottle';
import Section1 from '../../components/Basic/Section1';
import Section2 from '../../components/Basic/Section2';
import Section3 from '../../components/Basic/Section3';
import Section4 from '../../components/Basic/Section4';
import Section5 from '../../components/Basic/Section5';
import Section6 from '../../components/Basic/Section6';
import Section7 from '../../components/Basic/Section7';
import Section8 from '../../components/Basic/Section8';
import Section9 from '../../components/Basic/Section9';
import Section10 from '../../components/Basic/Section10';
import Section11 from '../../components/Basic/Section11';
import Section12 from '../../components/Basic/Section12';
import Section13 from '../../components/Basic/Section13';
import Shop from '../../components/Basic/Shop';
import Footer from '../../components/Basic/Footer';
import FloatingButton from '../../components/Basic/FloatingButton';

const LandingPage = () => {
  const matches = useMediaQuery('(max-width: 444px)');

  return (
    <Box sx={{ height: '100vh' }}>
      <FloatingButton />
      <Container maxWidth='xs' disableGutters sx={{ overflow: 'hidden' }}>
        <Banner />
        <Section1 />
        <BuyNow />
        <Section2 />
        <Section3 />
        <Bottle matches={matches} />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Section11 />
        <Section12 />
        <Section13 />
        <Shop />
        <Footer />
      </Container>
    </Box>
  );
};
export default LandingPage;
