import { Box, Typography } from '@mui/material';
import shield from '../../assets/shield.png';

const Section8 = () => {
  return (
    <Box
      sx={{
        mt: 4,
        bgcolor: 'primary.light',
        color: 'primary.contrastText',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: 2,
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-aos='zoom-out'
          data-duration={750}
          component='img'
          src={shield}
          sx={{ width: '60px' }}
        />
      </Box>
      <Typography
        data-aos='zoom-out'
        data-duration={750}
        align='center'
        sx={{
          fontWeight: 500,
          fontFamily: `'Montserrat', sans-serif`,
          fontSize: '0.875rem',
          lineHeight: 1.375,
        }}
      >
        Namun Bagaimana{' '}
        <Typography
          component='span'
          sx={{
            fontWeight: 700,
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '0.875rem',
          }}
        >
          Standar Keamanan
        </Typography>{' '}
        dari Produk{' '}
        <Typography
          component='span'
          sx={{
            fontWeight: 700,
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '0.875rem',
          }}
        >
          Lunoar Basic Serum
        </Typography>{' '}
        Sendiri ?
      </Typography>
    </Box>
  );
};
export default Section8;
