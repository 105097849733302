import { Box, Typography, useMediaQuery } from '@mui/material';
import bannerImage from '../../assets/banner_basic.png';
import bannerBackground from '../../assets/banner_background_basic.png';
import logo from '../../assets/logo_basic.png';
import BPOM from '../../assets/bpom_logo.png';

const Banner = () => {
  const smallScreen = useMediaQuery('(max-width: 340px)');
  const matches = useMediaQuery('(max-width: 444px)');

  return (
    <Box
      sx={{
        background: `url(${bannerBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pr: 2 }}>
        <Box
          data-aos='fade-down'
          component='img'
          src={logo}
          sx={{ width: '120px' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          // alignItems: 'flex-end',
          pr: 2,
        }}
      >
        <Box
          data-aos='fade'
          data-aos-duration={1500}
          component='img'
          src={bannerImage}
          sx={{
            width: matches ? '50vw' : '260px',
            mb: -0.2,
          }}
        />

        <Box
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            data-aos='fade-up'
            sx={{
              fontWeight: 700,
              // fontSize: smallScreen ? '1.125rem' : '1.25rem',
              fontSize: matches ? '5vw' : '1.25rem',
              fontFamily: `'Montserrat', sans-serif`,
              lineHeight: '22px',
              // color: '#fff',
              verticalAlign: 'top',
            }}
            color='primary.main'
          >
            Atasi Penuaan pada Kulit Wajahmu hanya dalam
            <Typography
              component='span'
              data-aos='fade'
              data-aos-delay={500}
              color='primary.main'
              sx={{
                // color: '#fff',
                // fontSize: smallScreen ? '2rem' : '2.5rem',
                fontSize: matches ? '8vw' : '2.5rem',
                fontFamily: `'Montserrat', sans-serif`,
                fontWeight: 700,
                lineHeight: 0.875,
              }}
            >
              {' '}
              7 Hari
            </Typography>
          </Typography>

          {/* <Typography
            align='right'
            data-aos='fade'
            sx={{
              fontWeight: 600,
              fontStyle: 'italic',
              color: '#fff',
              fontSize: '0.75rem',
              fontFamily: `'Source Sans Pro', sans-serif`,
              alignSelf: 'end',
              mt: smallScreen ? 4 : 8,
              mb: 0.5,
            }}
          >
            tanpa ke klinik atau dokter
          </Typography> */}
          <Box
            data-aos='fade'
            data-aos-duration={1500}
            component='img'
            src={BPOM}
            sx={{
              width: '57px',
              mb: 2,
              display: 'flex',
              alignSelf: 'flex-end',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default Banner;
