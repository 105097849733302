import { Box, Typography, useMediaQuery } from '@mui/material';
import product from '../../assets/lunoar_premium.png';
import AOS from 'aos';
import { useEffect } from 'react';

const Product = () => {
  const smallScreen = useMediaQuery('(max-width: 340px)');

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <Box
      sx={{
        pt: 4,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        px: 2,
      }}
    >
      <Box
        data-aos="fade"
        data-aos-duration={2000}
        component="img"
        src={product}
        sx={{ width: '40%' }}
      />
      <Box data-aos="fade-up">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: smallScreen ? '1rem' : '1.25rem',
          }}
        >
          Lunoar
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: smallScreen ? '0.75rem' : '0.9125rem',
          }}
        >
          Perfect Serum for Glowing Skin and Anti-Aging
        </Typography>
      </Box>
    </Box>
  );
};
export default Product;
