import { Box, Typography } from '@mui/material';
import safe1 from '../../assets/safe1_basic.png';
import safe2 from '../../assets/safe2_basic.png';
import safe3 from '../../assets/safe3_basic.png';

const Section10 = () => {
  const items = [
    {
      image: safe1,
      name: 'AMAN UNTUK BUSUI & BUMIL',
      desc: 'Lunoar Aman untuk Bumil & Busui karena Tidak Mengandung Zat Retinol, Alkohol Parfum,Paraben, Sulfate danbahan keras lainnya',
      padding: 3,
      width: 0.8,
    },
    {
      image: safe2,
      name: 'TANPA HYDROQUINONE',
      desc: 'Hydroquinone merupakan Zat yang bisa membuat Kulit Terbakar & Iritasi',
      width: 0.625,
    },
    {
      image: safe3,
      name: 'TANPA MERCURY',
      desc: 'Merkuri sendiri juga salah satu Zat yang berbahaya dan sangat tidak dianjurkan untuk bahan Skincare',
      width: 0.625,
    },
  ];
  return (
    <Box sx={{ px: 2 }}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              data-aos='zoom-out'
              data-aos-duration={500}
              data-aos-delay={(index + 1) * 100}
              component='img'
              src={item.image}
              sx={{ width: item.width }}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography
              data-aos='zoom-out'
              data-aos-duration={500}
              data-aos-delay={(index + 1) * 100}
              align='center'
              sx={{
                fontFamily: `'Poppins', sans-serif`,
                fontSize: '1.5rem',
                fontWeight: 500,
              }}
            >
              {item.name}
            </Typography>
            <Typography
              data-aos='zoom-out'
              data-aos-duration={500}
              data-aos-delay={(index + 1) * 100}
              align='center'
              sx={{
                fontWeight: 500,
                fontFamily: `'Montserrat', sans-serif`,
                fontSize: '0.875rem',
                lineHeight: 1.375,
                px: item.padding,
              }}
            >
              {item.desc}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default Section10;
