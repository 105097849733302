import { Box, Typography, useMediaQuery } from '@mui/material';

const Footer = () => {
  const breakpointXS = useMediaQuery('(min-width :600px)');

  return (
    <Box sx={{ py: 2, bgcolor: '#C8E8E5', px: 2 }}>
      <Typography
        align="center"
        fontWeight={500}
        fontSize={breakpointXS ? '0.75rem' : '0.625rem'}
      >
        Lunoar &copy; A Danmogot.com Company 2013-2022
      </Typography>
    </Box>
  );
};
export default Footer;
