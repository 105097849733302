import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import BasicLandingPage from './pages/Basic/LandingPage';
import PremiumLandingPage from './pages/Premium/LandingPage';
import { useWindowSize } from 'react-use';

function App() {
  const { width, height } = useWindowSize();

  AOS.init({ once: true, easing: 'ease-in' });

  useEffect(() => {
    ReactPixel.init('779383636744237', {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    AOS.refreshHard();
  }, [width, height]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/basic" replace={true} />} />
      <Route path="/basic" element={<BasicLandingPage />} />
      <Route path="/premium" element={<PremiumLandingPage />} />
    </Routes>
  );
}

export default App;
