import { Box, Typography } from '@mui/material';
import problem from '../../assets/problem_basic.png';

const Section2 = () => {
  const problems = [
    'Kulit wajah cepat keriput',
    'Banyak bintik hitam pada wajah',
    'Kerutan di sudut luar mata',
    'Kulit wajah terlihat kusam dan terasa kering',
    'Jerawat sering muncul & sulit disembuhkan',
    'Ketidak cocokan dengan serum wajah berbahan Retinol',
  ];

  return (
    <Box sx={{ mt: 4, px: 4 }}>
      <Box
        data-aos='zoom-out'
        data-aos-duration={1000}
        component='img'
        src={problem}
        sx={{ width: 1 }}
      />
      <Box sx={{ mt: 4 }}>
        <Typography
          data-aos='fade-right'
          data-aos-duration={500}
          sx={{ fontWeight: 700, fontFamily: `'Montserrat', sans-serif` }}
          color='primary.main'
        >
          Pernahkah Kamu Alami ?
        </Typography>
        <ul style={{ marginBlock: '4px 0', paddingInlineStart: '20px' }}>
          {problems.map((problem, index) => (
            <li
              data-aos='fade-right'
              data-aos-duration={500}
              data-aos-delay={(index + 1) * 100}
              key={index}
              style={{
                fontFamily: `'Source Sans Pro', sans-serif`,
                fontSize: '0.875rem',
              }}
            >
              {problem}
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
export default Section2;
