import { Box, Typography } from '@mui/material';
import bpom from '../../assets/bpom.png';

const Section9 = () => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-aos='zoom-out-right'
          data-aos-duration={500}
          component='img'
          src={bpom}
          sx={{ width: 0.75 }}
        />
      </Box>
      <Typography
        data-aos='zoom-out-right'
        data-aos-duration={500}
        align='center'
        sx={{ mt: 2 }}
      >
        <Typography
          component='span'
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '1.5rem',
            color: '#0F0E8D',
          }}
        >
          Sudah Tersertifikasi
        </Typography>{' '}
        <Typography
          component='span'
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '1.5rem',
            color: '#149D47',
          }}
        >
          BPOM
        </Typography>
      </Typography>
      <Typography
        data-aos='zoom-out-right'
        data-aos-duration={500}
        align='center'
        sx={{
          mt: 1,
          fontFamily: `'Montserrat', sans-serif`,
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: 1.375,
        }}
      >
        Produk Lunoar sendiri telah mendapatkan Sertifikasi BPOM dan Aman untuk
        semua jenis Kulit
      </Typography>
    </Box>
  );
};
export default Section9;
