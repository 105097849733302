import { Box, Typography, useMediaQuery } from '@mui/material';
import product from '../../assets/lunoar_basic.png';

const Section12 = () => {
  const smallScreen = useMediaQuery('(max-width: 340px)');

  return (
    <Box sx={{ mt: 4, px: 2, display: 'flex', alignItems: 'center', gap: 2.5 }}>
      <Box
        data-aos='zoom-out'
        data-aos-duration={750}
        component='img'
        src={product}
        sx={{ width: 0.4 }}
      />
      <Box>
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: smallScreen ? '1rem' : '1.25rem',
            fontWeight: 700,
          }}
          color='primary.main'
        >
          Lunoar Basic
        </Typography>
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          data-aos-delay={100}
          sx={{
            fontFamily: `'Source Sans Pro', sans-serif`,
            fontSize: smallScreen ? '0.75rem' : '0.875rem',
          }}
        >
          <Typography
            component='span'
            sx={{
              fontFamily: `'Source Sans Pro', sans-serif`,
              fontWeight: 600,
              fontSize: smallScreen ? '0.75rem' : '0.875rem',
            }}
          >
            Perfect Serum
          </Typography>{' '}
          for Glowing Skin
        </Typography>
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          data-aos-delay={100}
          sx={{
            mt: 2,
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 700,
            fontSize: smallScreen ? '1rem' : '1.25rem',
          }}
        >
          Rp50.000,-
        </Typography>
      </Box>
    </Box>
  );
};
export default Section12;
