import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ p: 2, bgcolor: 'primary.light', color: 'primary.contrastText' }}>
      <Typography align='center' sx={{ fontWeight: 500, fontSize: '0.625rem' }}>
        Lunoar Copyright. A Danmogot.com Company 2013-2022
      </Typography>
    </Box>
  );
};
export default Footer;
