import { Box, Typography } from '@mui/material';

const Section6 = () => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Box
        data-aos='zoom-out'
        data-duration={750}
        sx={{
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          borderRadius: '10px',
          p: 2,
        }}
      >
        <Typography
          align='center'
          sx={{
            fontFamily: `'Poppins', sans-serif`,
            fontSize: '0.875rem',
            fontWeight: 500,
          }}
        >
          Terdapat juga{' '}
          <Typography
            component='span'
            sx={{
              fontFamily: `'Poppins', sans-serif`,
              fontSize: '0.875rem',
              fontWeight: 700,
            }}
          >
            FISH COLLAGEN <span style={{ fontWeight: 500 }}>MURNI</span>
          </Typography>{' '}
          yang efektif untuk mengencangkan kulit wajah anda
        </Typography>
      </Box>
    </Box>
  );
};
export default Section6;
