import { Box, Typography, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import asset1 from '../../assets/testimony1.mp4';
import asset2 from '../../assets/testimony2.mp4';
import asset3 from '../../assets/testimony3.mp4';
import asset4 from '../../assets/testimony4.jpg';

const Section11 = () => {
  const matches = useMediaQuery('(max-width:444px');

  const assets = [asset1, asset2, asset3, asset4];
  return (
    <Box sx={{ mt: 4 }}>
      <Box
        sx={{
          px: 2,
          py: 4,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
        }}
      >
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          align='center'
          sx={{
            fontWeight: 700,
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '1.25rem',
          }}
        >
          Masih Ragu ?
        </Typography>
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          data-aos-delay={250}
          align='center'
          sx={{
            fontWeight: 700,
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '1.25rem',
          }}
        >
          Biarkan Mereka Menjawab
        </Typography>
        <Box sx={{ mt: 4 }} data-aos='zoom-out'>
          <Carousel
            activeIndicatorIconButtonProps={{ style: { color: '#fff' } }}
            interval={5000}
          >
            {assets.map((asset, index) => {
              return index === 3 ? (
                <Box
                  key={index}
                  component='img'
                  src={asset}
                  sx={{
                    width: 1,
                    borderRadius: '10px',
                    minHeight: matches ? '164.03vw' : '732.3px',
                  }}
                />
              ) : (
                <video
                  key={index}
                  autoPlay
                  muted
                  loop
                  playsInline
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    minHeight: matches ? '164.03vw' : '732.3px',
                  }}
                >
                  <source src={asset} type='video/mp4' />
                </video>
              );
            })}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};
export default Section11;
