import { Box, Typography, useMediaQuery } from '@mui/material';
import bpom from '../../assets/bpom.png';
import AOS from 'aos';
import { useEffect } from 'react';

const Certified = () => {
  const smallScreen = useMediaQuery('(max-width: 340px)');

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <Box
      sx={{
        pt: 4,
        display: 'flex',
        alignItems: 'center',
        gap: smallScreen ? 1 : 2,
        justifyContent: smallScreen ? 'space-between' : 'center',
        px: smallScreen ? 2 : 4,
      }}
    >
      <Box
        data-aos="fade-right"
        component="img"
        src={bpom}
        sx={{ width: 0.35, maxWidth: smallScreen ? '70px' : '80px' }}
      />
      <Typography
        data-aos="fade-right"
        sx={{
          fontWeight: 600,
          fontSize: smallScreen ? '0.875rem' : '0.9125rem',
        }}
      >
        Sudah Tersertifikasi BPOM
      </Typography>
    </Box>
  );
};
export default Certified;
