import { Box, Typography } from '@mui/material';
import image from '../../assets/cod.png';

const Section13 = () => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-aos='zoom-out'
          data-aos-duration={750}
          data-aos-delay={100}
          component='img'
          src={image}
          sx={{
            width: 0.8,
            borderRadius: '10px',
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          data-aos-delay={100}
          color='primary.main'
          align='center'
          sx={{
            fontWeight: 500,
            fontSize: '1.5rem',
            fontFamily: `'Poppins', sans-serif`,
          }}
        >
          FREE ONGKIR
        </Typography>
        <Typography
          data-aos='zoom-out-up'
          data-aos-duration={500}
          data-aos-delay={100}
          align='center'
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '0.875rem',
            fontWeight: 500,
            px: 3,
          }}
        >
          Bebas ongkir untuk seluruh wilayah medan dan sekitarnya
        </Typography>
      </Box>
    </Box>
  );
};
export default Section13;
