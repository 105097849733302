import { Box, Button } from '@mui/material';
import ReactPixel from 'react-facebook-pixel';
import tokopedia from '../../assets/tokopedia.png';
import shopee from '../../assets/shopee.png';

const Shop = () => {
  const items = [
    {
      name: 'Shopee',
      icon: shopee,
      color: '#EE4D2D',
      bgcolor: '#F5F5F5',
      link: 'https://shopee.co.id/Lunoar-Skin-Barrier-Basic-i.712293199.17358609383?sp_atk=b8177ea4-2881-44f3-a890-b162841a0dab&xptdk=b8177ea4-2881-44f3-a890-b162841a0dab',
    },
    {
      name: 'Tokopedia',
      icon: tokopedia,
      color: '#fff',
      bgcolor: '#3CBB38',
      link: 'https://www.tokopedia.com/lunoar/lunoar-skin-barrier-serum-basic',
    },
  ];
  return (
    <Box
      sx={{
        mt: 4,
        mb: 12,
        px: 2,
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
      }}
    >
      {items.map((item, index) => {
        return (
          <Button
            data-aos="zoom-out-right"
            data-aos-duration={500}
            data-aos-delay={(index + 1) * 100}
            fullWidth
            key={index}
            id={`button-${index + 1}`}
            variant="contained"
            sx={{
              py: '12px',
              color: item.color,
              bgcolor: item.bgcolor,
              boxShadow: '0 4px 4px 0 rgba(0,0,0,0.25)',
              '&:hover': {
                color: item.color,
                bgcolor: item.bgcolor,
                boxShadow: '4px 4px 4px 0 rgba(0,0,0,0.25)',
              },
              fontSize: '1.25rem',
              fontWeight: 600,
            }}
            onClick={() => {
              ReactPixel.track('Lead', {
                content_name: item.name,
              });

              window.open(item.link);
            }}
            startIcon={
              <img src={item.icon} style={{ height: '34px' }} alt={item.name} />
            }
          >
            {item.name}
          </Button>
        );
      })}
    </Box>
  );
};
export default Shop;
