import { Box, Typography, useMediaQuery } from '@mui/material';
import bannerImage from '../../assets/banner_premium.png';
import { useEffect } from 'react';
import AOS from 'aos';
import bannerBackground from '../../assets/banner_background_premium.png';
import Header from '../Header';

const Banner = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const foldScreen = useMediaQuery('(max-width: 300px)');
  const smallScreen = useMediaQuery('(max-width: 340px)');

  return (
    <Box
      sx={{
        px: 2,
        gap: 1,
        background: `url(${bannerBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          mt: 2,
        }}
      >
        <Box
          data-aos="fade"
          data-aos-duration={2000}
          component="img"
          src={bannerImage}
          sx={{
            width: 1,
            maxWidth: foldScreen ? '180px' : smallScreen ? '200px' : '230px',
          }}
        />
        <Box data-aos="fade-up" sx={{ width: '30%', alignSelf: 'center' }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: foldScreen
                ? '0.75rem'
                : smallScreen
                ? '0.875rem'
                : '1rem',
              lineHeight: smallScreen ? 1.3 : 1.4,
              pr: smallScreen ? 0 : 2,
            }}
          >
            Tampak Lebih Muda Dengan Skin Barrier Serum Dari Lunoar
          </Typography>
          <Box
            sx={{
              color: '#fff',
              bgcolor: '#4D4D4D',
              p: 1,
              borderRadius: '10px',
              mt: smallScreen ? 0.5 : 1,
              width: '100%',
              maxWidth: smallScreen ? '90px' : '110px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: smallScreen ? '0.5rem' : '0.625rem',
              }}
            >
              Harga mulai dari
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: smallScreen ? '0.625rem' : '0.75rem',
              }}
            >
              Rp 135.000
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Banner;
