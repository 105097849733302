import { Box, Typography } from '@mui/material';

const Section3 = () => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Box
        data-aos='zoom-out'
        data-duration={750}
        sx={{
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          borderRadius: '10px',
          p: 2,
        }}
      >
        <Typography
          align='center'
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            fontSize: '0.875rem',
          }}
        >
          Tapi tenang, Masalah-masalah diatas bisa diatasi dengan :{' '}
          <Typography
            component='span'
            sx={{
              fontFamily: `'Montserrat', sans-serif`,
              fontWeight: 700,
              fontSize: '0.875rem',
              textTransform: 'uppercase',
            }}
          >
            Lunoar Skin Barrier Serum
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
export default Section3;
