import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import AOS from 'aos';
import background from '../../assets/sec-2_premium.png';

const Ingredient = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const smallScreen = useMediaQuery('(max-width:340px)');

  const sources = [
    { id: 1, source: 'Azeloglicinia' },
    { id: 2, source: 'Daisy Extract' },
    { id: 3, source: 'Galactomyces' },
    { id: 4, source: 'Amino Acid' },
    { id: 5, source: 'Enzim' },
    { id: 6, source: 'B3 & B5' },
  ];

  return (
    <Box
      sx={{
        py: 4,
        mt: 4,
        px: 2,
        background: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      <Typography
        data-aos='fade-up'
        sx={{
          fontWeight: 700,
          fontSize: smallScreen ? '0.875rem' : '1rem',
          WebkitTextStroke: '1px rgba(255,255,255,0.5)',
        }}
        align='center'
      >
        Serum Barrier Lunoar ini kaya akan
      </Typography>
      <Box
        data-aos='fade-up'
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {sources.map((item) => (
          <Box
            key={item.id}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: smallScreen ? '90px' : '100px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#fff',
                borderRadius: '50%',
                height: smallScreen ? '90px' : '100px',
                width: smallScreen ? '90px' : '100px',
                flexDirection: 'column',
                p: smallScreen ? 1 : 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize:
                    smallScreen && item.id === 6
                      ? '0.875rem'
                      : item.id === 6
                      ? '1rem'
                      : smallScreen && !item.id === 6
                      ? '0.7rem'
                      : '0.75rem',
                }}
                align='center'
              >
                {item.source}
              </Typography>
              {item.id === 6 && (
                <Typography
                  align='center'
                  sx={{
                    fontWeight: 700,
                    fontSize: smallScreen ? '0.7rem' : '0.75rem',
                  }}
                >
                  Vitamin
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default Ingredient;
