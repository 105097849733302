import { Box, Typography, useMediaQuery } from '@mui/material';
import bottle from '../../assets/bottle_premium.png';
import { useEffect } from 'react';
import AOS from 'aos';

const Benefit = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const smallScreen = useMediaQuery('(max-width:340px)');

  const benefits = [
    { id: 1, benefit: 'Melindungi kulit dari Sinar UV' },
    { id: 2, benefit: 'Menghilangkan bekas jerawat' },
    { id: 3, benefit: 'Menghilangkan flek hitam' },
    { id: 4, benefit: 'Mencerahkan kulit' },
    { id: 5, benefit: 'Antiseptik' },
    { id: 6, benefit: 'Menutrisi kulit' },
  ];

  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box data-aos="fade-right">
          <Typography
            fontWeight={600}
            sx={{ fontSize: smallScreen ? '0.875rem' : '1rem' }}
          >
            Diperkaya dengan
          </Typography>
          <Typography
            fontWeight={800}
            sx={{ fontSize: smallScreen ? '0.875rem' : '1rem' }}
          >
            Theranexamic Acid
          </Typography>
          <ul style={{ listStyleType: 'disc', paddingInlineStart: '30px' }}>
            {benefits.map((item) => (
              <li
                key={item.id}
                style={{
                  fontWeight: '500',
                  fontSize: smallScreen ? '0.8rem' : '0.875rem',
                }}
              >
                {item.benefit}
              </li>
            ))}
          </ul>
        </Box>
        <Box
          data-aos="fade"
          data-aos-duration={2000}
          component="img"
          src={bottle}
          sx={{ width: '50px', mr: smallScreen ? 2 : 4 }}
        />
      </Box>
    </Box>
  );
};
export default Benefit;
