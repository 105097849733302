import { Box } from '@mui/material';
import Aos from 'aos';
import { useEffect } from 'react';
import logo from '../assets/lunoar.png';

const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  return (
    <Box sx={{ pt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-aos="fade-down"
          component="img"
          src={logo}
          sx={{ width: '120px' }}
        />
      </Box>
    </Box>
  );
};
export default Header;
