import { Box, Typography } from '@mui/material';

const Section4 = () => {
  return (
    <Box sx={{ px: 2 }}>
      <Typography
        data-aos='zoom-in-up'
        data-aos-duration={500}
        sx={{ fontWeight: 700, fontFamily: `'Montserrat', sans-serif` }}
        color='primary.main'
      >
        Mengapa Kulitmu Membutuhkan Serum dari Lunoar ?
      </Typography>
      <Typography
        data-aos='zoom-in-up'
        data-aos-duration={500}
        data-aos-delay={250}
        sx={{
          mt: 2,
          fontSize: '0.875rem',
          fontFamily: `'Source Sans Pro', sans-serif`,
          lineHeight: 1.4,
        }}
      >
        Hal ini dikarenakan kandungan Fish Collagen Murni pada Lunoar dapat
        mengatasi Penuaan dan Aman untuk Kulit Sensitifmu, Tidak Menimbulkan
        Kemerahan, Bengkak hingga Melepuh <br />
        <br /> Tentunya Serum ini juga 99.99% aman untuk{' '}
        <Typography
          component='span'
          sx={{
            fontSize: '0.875rem',
            fontFamily: `'Source Sans Pro', sans-serif`,
            lineHeight: 1.4,
            textTransform: 'uppercase',
          }}
        >
          WANITA HAMIL DAN MENYUSUI
        </Typography>
      </Typography>
    </Box>
  );
};
export default Section4;
