import { Box, Container } from '@mui/material';
import Footer from '../../components/Footer';
import GetIt from '../../components/GetIt';
import Banner from '../../components/Premium/Banner';
import Benefit from '../../components/Premium/Benefit';
import Certified from '../../components/Premium/Certified';
import Ingredient from '../../components/Premium/Ingredient';
import Product from '../../components/Premium/Product';
import Result from '../../components/Premium/Result';

const LandingPage = () => {
  return (
    <Box sx={{ height: '100vh' }}>
      <Container maxWidth="xs" disableGutters>
        <Banner />
        <Certified />
        <Product />
        <Ingredient />
        <Benefit />
        <Result />
        <GetIt />
        <Footer />
      </Container>
    </Box>
  );
};
export default LandingPage;
