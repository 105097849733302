import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { ShoppingCartOutlined as CartIcon } from '@mui/icons-material';

const BuyNow = () => {
  const matches = useMediaQuery('(max-width: 444px)');

  return (
    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', px: 2 }}>
      {/* <Button
        data-aos="zoom-out"
        data-duration={750}
        variant="contained"
        // startIcon={<CartIcon />}
        sx={{
          fontSize: smallScreen && '0.75rem',
          px: smallScreen ? '16px' : '24px',
        }}
        onClick={() =>
          window.open(
            'https://api.whatsapp.com/send/?phone=6282288898001&text=Halo+Siss,%20+Saya+Mau+Tanya2+tentang+Serum+Lunoar+dan+Promo+Diskonnya+donkk...&app_absent=0'
          )
        }
      >
        FREE KONSULTASI VIA WA
      </Button> */}
      <Typography
        align='center'
        sx={{
          fontSize: '1.25rem',
          fontFamily: `'Montserrat', sans-serif`,
          fontWeight: 700,
          lineHeight: 1.25,
          mx: 4,
        }}
        color='primary.main'
      >
        BACA INI SAMPAI HABIS UNTUK ATASI PENUAAN DINI
      </Typography>
    </Box>
  );
};
export default BuyNow;
