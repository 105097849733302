import ReactPixel from 'react-facebook-pixel';
import { Box, Typography, useMediaQuery } from '@mui/material';
import AOS from 'aos';
import { useEffect } from 'react';
import shopee from '../assets/shopee.png';
import tokopedia from '../assets/tokopedia.png';

const GetIt = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const smallScreen = useMediaQuery('(max-width: 340px)');

  const merchants = [
    {
      id: 1,
      image: tokopedia,
      name: 'Tokopedia',
      link: 'https://www.tokopedia.com/lunoar',
    },
    {
      id: 2,
      image: shopee,
      name: 'Shopee',
      link: 'https://shopee.co.id/lunoar.id',
    },
  ];

  return (
    <Box sx={{ py: 4, px: 2 }}>
      <Typography
        data-aos="fade-right"
        sx={{
          fontWeight: 600,
          fontSize: smallScreen ? '0.875rem' : '1rem',
        }}
      >
        Dapatkan sekarang juga!
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          mt: 2,
          justifyContent: 'center',
        }}
      >
        {merchants.map((item, index) => (
          <Box
            component="a"
            data-aos={'fade-right'}
            key={item.id}
            id={`button-${item.id}`}
            sx={{
              bgcolor: '#C8E8E5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              height: smallScreen ? '40px' : '50px',
              width: 1,
              p: 1,
              borderRadius: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              ReactPixel.track('Lead', {
                content_name: item.name,
              });

              window.open(item.link);
            }}
          >
            <Box component="img" src={item.image} sx={{ height: 1 }} />
            <Typography
              align="center"
              sx={{
                width: 1,
                fontSize: smallScreen ? '0.875rem' : '1rem',
                fontWeight: 500,
              }}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default GetIt;
