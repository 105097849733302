import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import AOS from 'aos';

const Result = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const smallScreen = useMediaQuery('(max-width:340px)');

  const results = [
    { id: 1, result: '+ 80% Smoothness', width: '80%', minWidth: '210px' },
    { id: 2, result: '+ 21% Brightness', width: '45%', minWidth: '165px' },
    { id: 3, result: '- 21% Fine Lines', width: '45%', minWidth: '165px' },
    { id: 4, result: '- 17% Pores', width: '25%', minWidth: '120px' },
  ];

  return (
    <Box sx={{ bgcolor: '#C8E8E5', mt: 4, py: 4, px: 2 }}>
      <Typography
        data-aos="fade-right"
        sx={{ fontWeight: 600, fontSize: smallScreen ? '0.875rem' : '1rem' }}
      >
        Hasil setelah penggunaan 1 botol Lunoar
      </Typography>
      <Box
        data-aos="fade-right"
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          justifyContent: 'center',
        }}
      >
        {results.map((item) => (
          <Box
            key={item.id}
            sx={{
              bgcolor: '#088FAB',
              borderRadius: '0px 10px 10px 0px',
              color: '#fff',
              width: item.width,
              minWidth: item.minWidth,
              fontSize: '0.75rem',
              p: 1,
              fontWeight: 500,
            }}
          >
            {item.result}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default Result;
